import {
  createMuiTheme
} from "@material-ui/core";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#eb3237'
    },
    secondary: {
      main: '#333'
    }
  },
})