import { Button, Grid, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { Header } from '../organisms'

const GraciasPage = () => {
  const history = useHistory()

  // scrolls to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const handleVerMas = () => {
    history.push('/#productos')
  }
  return (
    <div className="products">
      <Header />
      <div className="main-content product-page">
        <h3 className="page-title">
          <NavLink
            onClick={() => history.goBack()}
            className="btn btn-secondary"
            to="/productos"
            role="button"
          >
            <i className="fa fa-caret-left"></i> VOLVER
          </NavLink>
        </h3>

        <div className="container" style={{ paddingBottom: '50px' }}>
          <Grid
            container
            justify="center"
            style={{ margin: '50px 0px' }}
            spacing={4}
          >
            <Grid item>
              <Typography variant="h2" color="primary" align="center">
                ¡Gracias por tu consulta!
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="center" gutterBottom>
                Nos pondremos en contacto contigo a la brevedad.
              </Typography>
            </Grid>
            <Grid container spacing={2} justify="center">
              <Grid item>
                <Typography variant="subtitle1">
                  Mientras tanto podés{' '}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleVerMas}
                >
                  VER MÁS PRODUCTOS
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default GraciasPage
