import React from 'react'
import { Route, Switch } from 'react-router-dom'

import {
  Contacto,
  HomePage,
  Nosotros,
  NotFound,
  ProductDetailPage,
  ProductsPage,
} from './pages'
import { Footer } from './organisms'
import GraciasPage from './pages/GraciasPage'

const Routes = () => {
  return (
    <>
      <Switch>
        <Route exact path={'/'} component={HomePage} />
        <Route exact path={'/contacto'} component={Contacto} />
        <Route exact path={'/nosotros'} component={Nosotros} />
        <Route exact path={'/productos'} component={ProductsPage} />
        <Route exact path={'/detalle'} component={ProductDetailPage} />
        <Route exact path={'/gracias'} component={GraciasPage} />
        <Route component={NotFound} />
      </Switch>
      <Footer />
    </>
  )
}

export default Routes
