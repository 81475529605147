import React, { useState } from 'react'
import { NavLink, useLocation, useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { getProduct } from '../../services/ecwid.service'
import { Header } from '../organisms'
import CarouselImages from '../organisms/product-detail/CarouselImages'
import { graciasUrl, urlMail } from '../../config'
import { Button, makeStyles, TextField } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  input: {
    fontSize: '13px',
    marginBottom: '10px',
  },
}))

const ProductDetailPage = () => {
  const location = useLocation()
  const history = useHistory()
  const [currentProduct, setCurrentProduct] = useState({})
  const [productImages, setProductImages] = useState([])

  const classes = useStyles()
  // scrolls to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  // get product from ecwid based on the url
  useEffect(() => {
    let productId = new URLSearchParams(location.search).get('producto')
    if (productId) {
      getProduct({ productId }).then((productResult) => {
        // setProductMainImage(productResult.imageUrl)
        setProductImages([
          productResult.imageUrl,
          ...productResult.galleryImages.map((i) => i.imageUrl),
        ])
        setCurrentProduct(productResult)
      })
    }
  }, [location])

  return (
    <div className="products">
      <Header />
      <div className="main-content product-page">
        <h3 className="page-title">
          <NavLink
            onClick={() => history.goBack()}
            className="btn btn-secondary"
            to="/productos"
            role="button"
          >
            <i className="fa fa-caret-left"></i> VOLVER
          </NavLink>
        </h3>

        <div className="container" style={{ paddingBottom: '50px' }}>
          <div className="row">
            <div className="product-image-container col-lg-5">
              {!!currentProduct && (
                <CarouselImages
                  productName={currentProduct.name}
                  images={productImages}
                />
              )}
            </div>
            <div className="product-details col-lg-7">
              <header>
                <h2>
                  {currentProduct.name}{' '}
                  <span className="float-right code">
                    Cód. {currentProduct.sku}
                  </span>
                </h2>
              </header>
              <p
                className="product-description"
                dangerouslySetInnerHTML={{ __html: currentProduct.description }}
              ></p>
              <form
                id="product-consultation"
                className="product-consultation"
                method="POST"
                action={urlMail}
              >
                <div>
                  <span className="boxed-title">
                    CONSULTAR POR ESTE ARTÍCULO
                  </span>
                  <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                    className="float-right"
                  >
                    ENVIAR <i className="ml-1 fa fa-caret-right"></i>
                  </Button>
                </div>

                <br />
                <br />

                <TextField
                  className={classes.input}
                  id="nombre"
                  name="nombre"
                  label="NOMBRE"
                  type="text"
                  placeholder="NOMBRE"
                  variant="outlined"
                  fullWidth
                  required
                />
                <TextField
                  className={classes.input}
                  id="telefono"
                  name="telefono"
                  label="TELÉFONO/CELULAR"
                  type="text"
                  placeholder="TELÉFONO/CELULAR"
                  variant="outlined"
                  required
                  fullWidth
                />
                <TextField
                  className={classes.input}
                  id="mailfrom"
                  name="mailfrom"
                  label="CORREO ELECTRÓNICO"
                  type="email"
                  placeholder="CORREO ELECTRÓNICO"
                  variant="outlined"
                  required
                  fullWidth
                />
                <TextField
                  className={classes.input}
                  name="mensaje"
                  id="mensaje"
                  label="MENSAJE"
                  placeholder="MENSAJE"
                  multiline
                  rows={3}
                  variant="outlined"
                  required
                  fullWidth
                />
                <input type="hidden" name="redirect" value={graciasUrl} />
                <input
                  type="hidden"
                  name="subject"
                  value="Consulta desde el sitio collagestore.com.uy"
                />
                {!!currentProduct && (
                  <>
                    <input
                      type="hidden"
                      name="nombre de producto"
                      value={currentProduct.name || ''}
                    />
                    <input
                      type="hidden"
                      name="codigo de producto"
                      value={currentProduct.sku || ''}
                    />
                    <input
                      type="hidden"
                      name="url de producto"
                      value={window.location.href}
                    />
                  </>
                )}
                <NavLink
                  className="btn btn-primary more-articles"
                  to="/contacto"
                  role="button"
                >
                  CONSULTAR POR MÁS ARTÍCULOS
                </NavLink>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductDetailPage
