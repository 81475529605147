import React, { useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import logoJpg from '../../../assets/img/logo.jpg'
import fbPng from '../../../assets/img/facebook.png'
import igPng from '../../../assets/img/instagram.png'
import { facebookPage, instagramPage } from '../../../utils'

const Header = ({ onSearchChange, onSearchSubmit }) => {
  const history = useHistory()
  const [searchTerm, setSearchTerm] = useState('')

  const onSubmit = (e) => {
    e.preventDefault()
    if (onSearchSubmit) {
      return onSearchSubmit()
    }
    const qs = `busqueda=${searchTerm}`
    const url = `productos?${qs}`
    history.push(url)
  }

  const onChange = (x) => {
    if (onSearchChange) {
      return onSearchChange(x.target.value)
    }
    setSearchTerm(x.target.value)
  }

  return (
    <div className="mast-head">
      <div className="container">
        <div className="top-bar">
          <span className="top-bar-txt">
            VENTAS SOLO POR MAYOR - SOLO PRODUCTOS PERSONALIZADOS -
            <a href="mailto:ventas@collage.com.uy">ventas@collage.com.uy</a>{' '}
            Tel.: 2480 0650
          </span>
        </div>

        <nav className="navbar navbar-expand-md">
          <NavLink className="navbar-brand logo" to="/">
            <img src={logoJpg} alt="" />
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarMain"
            aria-controls="navbarMain"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon fa fa-bars"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarMain">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item">
                <NavLink
                  exact
                  className="nav-link active"
                  to="/"
                >
                  INICIO <span className="sr-only">(current)</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  className="nav-link active"
                  to="/nosotros"
                >
                  NOSOTROS
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  className="nav-link active"
                  to="/#productos"
                >
                  PRODUCTOS
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  className="nav-link active"
                  to="/contacto"
                >
                  CONTACTO
                </NavLink>
              </li>
            </ul>
            <div className="my-2 my-md-0">
              <div className="social-media-icons float-left">
                <a
                  href={facebookPage}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Facebook"
                  className="facebook mr-2"
                >
                  <img
                    src={fbPng}
                    style={{ width: 35 }}
                    alt="logo de Facebook"
                  />
                </a>
                <a
                  href={instagramPage}
                  target="_blank"
                  rel="noopener noreferrer"
                  title="Instagram"
                  className="instagram"
                >
                  <img
                    src={igPng}
                    style={{ width: 35 }}
                    alt="logo de Instagram"
                  />
                </a>
              </div>

              <form
                id="home-search"
                className="home-search float-left"
                onSubmit={onSubmit}
              >
                <i onClick={onSubmit} className="fa fa-search mr-2" />
                <input
                  type="text"
                  placeholder="Buscar..."
                  onChange={onChange}
                />
              </form>
            </div>
          </div>
        </nav>
      </div>
    </div>
  )
}

export default Header
