const PUBLIC_TOKEN = 'public_zcaqCfe6k6yv3DufGD5YHhLSnf3NBxzr';
const CATEGORIES = {
  'NOVEDADES': '12479244',
}

const URLS = {
  BASE: 'https://app.ecwid.com/api/v3/5558040',
  PRODUCTS: 'products',
  CATEGORIES: 'categories'
}

const getProducts = async ({
  offset = 0,
  categoryId = CATEGORIES.NOVEDADES,
  keyword = ''
}) => {
  const url = `${URLS.BASE}/${URLS.PRODUCTS}?category=${categoryId}&token=${PUBLIC_TOKEN}&enabled=true&offset=${offset}&keyword=${keyword}`
  return fetch(url).then(res => res.json())
}

const getRootCategories = async () => {
  const url = `${URLS.BASE}/${URLS.CATEGORIES}?token=${PUBLIC_TOKEN}&parent=0`
  return fetch(url).then(res => res.json())
}

const getAllCategories = async () => {
  const url = `${URLS.BASE}/${URLS.CATEGORIES}?token=${PUBLIC_TOKEN}`
  return fetch(url).then(res => res.json())
}

const getCategoriesTree = async () => {
  // this method is not used but just here because it was nice to write :)
  const url = `${URLS.BASE}/${URLS.CATEGORIES}?token=${PUBLIC_TOKEN}`
  return fetch(url).then(res => res.json()).then(categories => {
    const cat = {}
    for (let index = 0; index < categories.items.length; index++) {
      const category = categories.items[index];
      if (cat[category.id]) {
        cat[category.id].push(category)
      } else {
        cat[category.id] = [category]
      }
    }
    return cat
  })
}

const searchProducts = async ({
  keyword,
  offset = 0
}) => {
  const url = `${URLS.BASE}/${URLS.PRODUCTS}?token=${PUBLIC_TOKEN}&enabled=true&offset=${offset}&keyword=${keyword}`
  return fetch(url).then(res => res.json())
  // if at some point want to search by id when no results were returned, add this:
  // const url = `${URLS.BASE}/${URLS.PRODUCTS}?token=${PUBLIC_TOKEN}&enabled=true&offset=${offset}&keyword=${keyword}`
  // const productsSearch = await (await fetch(url)).json()
  // if (productsSearch.items.length)
  //   return productsSearch
  // // if no results then search by id
  // const searchByIdUrl = `${URLS.BASE}/${URLS.PRODUCTS}?token=${PUBLIC_TOKEN}&enabled=true&offset=${offset}&productId=${keyword}`
  // return fetch(searchByIdUrl).then(res => res.json())
}

const getCategory = async ({
  categoryId
}) => {
  const url = `${URLS.BASE}/${URLS.CATEGORIES}/${categoryId}?token=${PUBLIC_TOKEN}`
  return fetch(url).then(res => res.json())
}

const getProduct = async ({
  productId
}) => {
  const url = `${URLS.BASE}/${URLS.PRODUCTS}/${productId}?token=${PUBLIC_TOKEN}`
  return fetch(url).then(res => res.json())
}

export {
  getAllCategories,
  getRootCategories,
  getCategoriesTree,
  getCategory,
  getProducts,
  getProduct,
  searchProducts
}