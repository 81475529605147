import React, { useEffect, useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import {
  getProducts,
  getCategory,
  getRootCategories,
  searchProducts,
} from '../../services/ecwid.service'
import { NavLink } from 'react-router-dom'
import { ProductsGrid } from '../organisms/ProductsGrid'
import { Header } from '../organisms'
import Loader from '../atoms/Loader'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  categories: {
    minWidth: '250px',
  },
}))

const ProductsPage = () => {
  const classes = useStyles()
  const location = useLocation()
  const history = useHistory()
  const [categories, setCategories] = useState([])
  const [currentCategory, setCurrentCategory] = useState({})
  const [productsList, setProductsList] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingCategories, setIsLoadingCategories] = useState(true)
  const [readyToRender, setReadyToRender] = useState(false)

  // setIsLoading when location changes
  useEffect(() => {
    setIsLoading(true)
  }, [location])

  // get categories
  useEffect(() => {
    getRootCategories({}).then((categories) => {
      setCategories(categories.items)
      setIsLoadingCategories(false)
    })
  }, [])

  // scrolls to top
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [productsList])

  // agarra la categoria de la url y carga el nombre y los productos de la categoria
  useEffect(() => {
    if (!location.search) return
    
    const busqueda = new URLSearchParams(window.location.search).get('busqueda')
    if (!!busqueda) {
      setSearchTerm(busqueda)
      searchProducts({ keyword: busqueda }).then((productsResult) => {
        setProductsList(productsResult.items)
        setIsLoading(false)
      })
    }
    let categoryId = new URLSearchParams(window.location.search).get(
      'categoria'
    )
    if (!busqueda && !categoryId) {
      categoryId = '12479244' //novedades
    }
    if (!!categoryId) {
      getCategory({ categoryId }).then((categoryResult) =>
        setCurrentCategory(categoryResult)
      )
      getProducts({ categoryId }).then((productsResult) => {
        setProductsList(productsResult.items)
        setIsLoading(false)
      })
    }
    setReadyToRender(true)
  }, [location, searchTerm])

  const categoryText = searchTerm.length
    ? `Resultados de la búsqueda "${searchTerm}"`
    : `Categoría ${
        !!currentCategory.name && currentCategory.name.toUpperCase()
      }`

  return (
    <div className="products">
      <Header />
      <div className="main-content product-list-page">
        <h3 className="page-title">
          <button
            className="btn btn-secondary"
            onClick={() => history.goBack()}
          >
            <i className="fa fa-caret-left mr-1"></i> VOLVER
          </button>
        </h3>
        <div className="container">
          <div className="row">
            <aside
              className="category-sidebar col-lg-3"
              style={{ marginBottom: '50px' }}
            >
              <div className={`bg ${classes.categories}`}>
                <h3>CATEGORÍAS</h3>
                {isLoadingCategories && <Loader />}
                {!isLoadingCategories &&
                  categories.map((category) => (
                    <NavLink
                      exact
                      to={`/productos?categoria=${category.id}`}
                      isActive={() => category.id === currentCategory.id}
                      key={category.id}
                      activeClassName="active"
                    >
                      {category.name} <span className="float-right dot"></span>
                    </NavLink>
                  ))}
              </div>
            </aside>
            <div
              className="product-list col-lg-9"
              style={{ minHeight: '500px', marginBottom: '100px' }}
            >
              {isLoading && <Loader />}
              {!isLoading && readyToRender && (
                <ProductsGrid
                  categoryText={categoryText}
                  productsList={productsList}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductsPage
