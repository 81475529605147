import React from 'react'
import logoPng from '../../assets/img/logo_promocionales_merchandising.png'
import { NavLink } from 'react-router-dom'
import { facebookPage, instagramPage } from '../../utils'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'

const Footer = () => {
  return (
    <div className="site-footer home">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-3 col-sm-6"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignSelf: 'center',
            }}
          >
            <img
              className="logo"
              src={logoPng}
              alt="logo pie de pagina"
              style={{ width: '230px' }}
            />
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="cont">
              <h4>DIRECCIÓN</h4>
              <span>Jaime Cibils 2504 of. 302</span>
              <br />
              <span>Montevideo Uruguay</span>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="cont">
              <h4>CONTACTO</h4>
              <span>
                email:{' '}
                <NavLink to="mailto:ventas@collage.com.uy">
                  ventas@collage.com.uy
                </NavLink>
              </span>
              <br />
              <span>teléfonos: 2480 0650 / 2481 2320</span>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6">
            <div className="cont">
              <h4>SIGUENOS EN</h4>
              <span>
                <a
                  href={facebookPage}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                  <FacebookIcon color="primary" className="ml-1" />
                </a>
              </span>
              <br />
              <span>
                <a
                  href={instagramPage}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Instagram
                  <InstagramIcon color="primary" className="ml-1" />
                </a>
              </span>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
