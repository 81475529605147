import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { getRootCategories } from '../../services/ecwid.service'
import CategoryItem from '../organisms/CategoryItem'
import Confian from '../organisms/Confian'
import { Header } from '../organisms'
import remerasSliderImg from '../../assets/img/slider/remeras.png'
import newPendriveSliderImg from '../../assets/img/slider/pendrives.png'
import caramanolasSliderImg from '../../assets/img/slider/caramanolas.png'
import bolsasSliderImg from '../../assets/img/slider/bolsas.png'
import Loader from '../atoms/Loader'
import { Grid, Hidden } from '@material-ui/core'

const scrollToRef = (ref) =>
  window.scrollTo({
    top: ref.current.offsetTop - 200,
    behavior: 'smooth',
  })

const HomePage = () => {
  const productsRef = useRef(null)
  const executeScroll = () => scrollToRef(productsRef)
  const [categories, setCategories] = useState([])

  useEffect(() => {
    getRootCategories({}).then((categories) => {
      setCategories(categories.items)
    })
  }, [])
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const location = useLocation()
  useEffect(() => {
    if (productsRef && location.hash.indexOf('#productos') !== -1) {
      executeScroll()
    }
  }, [productsRef, location.hash])

  return (
    <div className="home">
      <Header />

      <div className="main-content">
        <div
          id="home-carousel"
          className="home-carousel carousel slide"
          data-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <Grid container>
                <Hidden smDown>
                  <Grid
                    item
                    xs={3}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={caramanolasSliderImg}
                      alt="caramanolas slider"
                      className="img-fluid"
                      style={{ maxHeight: '250px' }}
                      // className="slider-img-01"
                    />
                  </Grid>
                </Hidden>
                <Grid item xs={12} md={6}>
                  <h2>
                    <span style={{ paddingRight: 125 }}>Artículos</span>
                    <strong>Publicitarios</strong>
                    <span style={{ fontSize: 32, paddingLeft: 245 }}>
                      con su logo
                    </span>
                  </h2>
                  <h3>Tel.: 2480 0650 - ventas@collage.com.uy</h3>
                  <a href="#productos" className="btn" onClick={executeScroll}>
                    Ver más <i className="fa fa-caret-right"></i>
                  </a>
                </Grid>
                <Hidden smDown>
                  <Grid
                    item
                    xs={3}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={bolsasSliderImg}
                      alt="bolsa slider"
                      className="img-fluid"
                      style={{ maxHeight: '250px' }}
                      // className="slider-img-02"
                    />
                  </Grid>
                </Hidden>
              </Grid>
            </div>
            <div className="carousel-item">
              <Grid container>
                <Hidden smDown>
                  <Grid
                    item
                    xs={3}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={remerasSliderImg}
                      alt="tazas slider"
                      className="img-fluid"
                      style={{ maxHeight: '250px' }}
                    />
                  </Grid>
                </Hidden>
                <Grid item xs={12} md={6}>
                  <h2>
                    <span style={{ paddingRight: 125 }}>Artículos</span>
                    <strong>Publicitarios</strong>
                    <span style={{ fontSize: 32, paddingLeft: 245 }}>
                      con su logo
                    </span>
                  </h2>
                  <h3>Tel.: 2480 0650 - ventas@collage.com.uy</h3>
                  <a href="#productos" className="btn" onClick={executeScroll}>
                    Ver más <i className="fa fa-caret-right"></i>
                  </a>
                </Grid>
                <Hidden smDown>
                  <Grid
                    item
                    xs={3}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src={newPendriveSliderImg}
                      alt="pendrives slider"
                      className="img-fluid"
                      style={{ maxHeight: '250px' }}
                    />
                  </Grid>
                </Hidden>
              </Grid>
            </div>
          </div>
          <a
            className="carousel-control-prev"
            href="#home-carousel"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Anterior</span>
          </a>
          <a
            className="carousel-control-next"
            href="#home-carousel"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="sr-only">Siguiente</span>
          </a>
        </div>

        <div
          ref={productsRef}
          id="#productos"
          className="featured-products products"
        >
          <div className="container">
            <h3 className="boxed-title">PRODUCTOS</h3>

            <div className="row">
              {!categories.length && <Loader />}
              {categories.map((category) => (
                <div
                  key={category.id}
                  className="col-lg-3 col-md-6 col-sm-12"
                  style={{ padding: 15 }}
                >
                  <CategoryItem category={category} />
                </div>
              ))}
            </div>
          </div>
        </div>

        <Confian />
      </div>
    </div>
  )
}

export default HomePage
