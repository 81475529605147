import React, { useState, useEffect } from 'react'
import { Tooltip } from '@material-ui/core'
import { NavLink } from 'react-router-dom'
import Pagination from '@material-ui/lab/Pagination'

export const ProductsGrid = ({ categoryText, productsList = [] }) => {
  const [pageNumber, setPageNumber] = useState(1)
  const itemsPerPage = 15
  const totalItems = productsList.length
  const [currentPageProducts, setCurrentPageProducts] = useState([])

  useEffect(() => {
    setCurrentPageProducts(
      productsList.slice(
        (pageNumber - 1) * itemsPerPage,
        pageNumber * itemsPerPage
      )
    )
  }, [pageNumber, productsList])

  const handlePageChange = (event, value) => {
    setPageNumber(value)
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }

  return (
    <>
      <h4 className="category-title">{categoryText}</h4>
      <div className="row">
        {currentPageProducts &&
          !!currentPageProducts.length &&
          currentPageProducts.map((product) => (
            <Tooltip
              key={product.id}
              title={`Ver detalle de ${product.name}`}
              arrow
              placement="top"
            >
              <div className="col-6 col-md-4">
                <div className="product">
                  <NavLink to={`/detalle?producto=${product.id}`}>
                    <img src={product.thumbnailUrl} alt="" />
                    <h3 className="product-name">{product.name}</h3>
                    <span className="product-code">Cód. {product.sku}</span>
                  </NavLink>
                </div>
              </div>
            </Tooltip>
          ))}
        {currentPageProducts && !currentPageProducts.length && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: 50,
            }}
          >
            <p>No encontramos resultados para tu búsqueda</p>
          </div>
        )}
      </div>
      <div className="row">
        <div className="mx-auto mt-3">
          <Paginator
            handleChange={handlePageChange}
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            page={pageNumber}
          />
        </div>
      </div>
    </>
  )
}

const Paginator = ({ page, handleChange, itemsPerPage, totalItems }) => {
  return (
    <>
      <Pagination
        color="primary"
        count={Math.ceil(totalItems / itemsPerPage)}
        page={page}
        onChange={handleChange}
        showFirstButton
        showLastButton
      />
    </>
  )
}
