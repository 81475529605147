import Grow from '@material-ui/core/Grow'
import React, { useState } from 'react'
import wpIconImage from '../../assets/img/whatsapp-icon.png'
import logoCollageWhite from '../../assets/img/logo-collage-white.png'
import closeIconWhite from '../../assets/img/x-close-icon-white.png'

const Whatsapp = () => {
  const [showChat, setShowChat] = useState(false)
  const [message, setMessage] = useState('')

  const timeNow = new Date()

  const onMessageChange = ({ target: { value } }) => setMessage(value)

  const handleSendClick = () => {
    window.open(
      `https://api.whatsapp.com/send?phone=59897006222&text=${message}`
    )
  }

  return (
    <div className="my-whatsapp-box">
      <Grow in={showChat}>
        <div
          // style={{ display: showChat ? 'block' : 'none' }}
          className="whatsapp-chat-box"
        >
          <div
            className="get-comment-close close-widget-top"
            onClick={() => setShowChat(false)}
          >
            <img
              style={{ maxWidth: '100%' }}
              src={closeIconWhite}
              alt="icono cierra chat"
            />
          </div>
          <div className="chat-header">
            <div className="row">
              <div className="col-9">
                <figure>
                  <img src={logoCollageWhite} alt="logo collage white" />
                </figure>
                <div className="empresa">
                  <span className="tt1">Collage Store</span>
                  <span className="tt2">Whatsapp</span>
                </div>
              </div>
              <div className="col-3 text-right">
                <i className="far fa-whatsapp"></i>
              </div>
            </div>
          </div>
          <div className="body">
            <div className="tag-label">Hoy</div>
            <div className="welcome_text arrow_box">
              Gracias por visitarnos. ¿Cómo podemos ayudarte?
              <span id="msg-time">{`${timeNow.getHours()}:${timeNow.getMinutes()}`}</span>
            </div>
            <p>&nbsp;</p>
          </div>
          <div className="foot">
            <div className="input-group">
              <textarea
                id="text_whatsapp"
                rows="1"
                className="form-control textarea_whatsapp"
                placeholder="Enviar mensaje"
                style={{ height: '38px' }}
                value={message}
                onChange={onMessageChange}
              ></textarea>
              <div className="input-group-append">
                <span
                  id="send_w"
                  className="input-group-text"
                  onClick={handleSendClick}
                >
                  <i className="mdi mdi-send"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Grow>
      <div className="sonar-wrapper">
        <div className="sonar-emitter">
          <div className="sonar-wave"></div>
        </div>
      </div>
      <div
        onClick={() => setShowChat(!showChat)}
        style={{ cursor: 'pointer' }}
        id="get-comment-btn"
        className="whatsapp-btns"
      >
        <img
          className="imgicon"
          style={{
            maxWidth: '50px',
            zIndex: 9999,
          }}
          src={wpIconImage}
          alt="whatsapp icono"
        />
      </div>
    </div>
  )
}

export default Whatsapp
