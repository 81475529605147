import React from 'react'
import { NavLink } from 'react-router-dom'

const CategoryItem = ({ category }) => {
  const { name, thumbnailUrl, id } = category
  const link = `productos?categoria=${id}`

  return (
    <NavLink to={link} className="product">
      <div className="product-info">
        {/* <h3 className="text-white mb-2">{name.toUpperCase()}</h3>
        <NavLink to={link} className='btn'>
          Ver más <i className="fa fa-caret-right"></i>
        </NavLink> */}
        {/* <NavLink className="btn btn-secondary" to={link} role="button">VER MÁS <i className="fa fa-caret-right"></i></NavLink> */}
        {/* <NavLink className="plus-sign" to={link} role="button"><i className="fa fa-plus"></i></NavLink> */}
        <img src={thumbnailUrl} alt={`imagen de categoria ${name}`} />
      </div>
    </NavLink>

  );
}

export default CategoryItem
