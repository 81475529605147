import React from 'react'
import confianImg from '../../assets/img/confian.png'

const Confian = () => {
  return (
    <div className="trusted-clients">
      <div className="container">
        <h4>
          CONFÍAN EN
          <br />
          <span className="big-txt">NOSOTROS</span>
        </h4>

        <div className="brands">
          <img
            src={confianImg}
            alt="empresas que confian en nosotros"
            className="img-fluid"
          />
        </div>
      </div>
    </div>
  )
}

export default Confian
