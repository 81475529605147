import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Header } from '../organisms'
import { Button, IconButton } from '@material-ui/core'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import PhoneIcon from '@material-ui/icons/Phone'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import { facebookPage, instagramPage } from '../../utils'
import { graciasUrl, urlMail } from '../../config'

const Contacto = () => {
  const history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="products">
      <Header />

      <div className="main-content nosotros" style={{ paddingBottom: '50px' }}>
        <h3 className="page-title">
          <button
            className="btn btn-secondary"
            onClick={() => history.goBack()}
          >
            <i className="fa fa-caret-left mr-1"></i> VOLVER
          </button>
        </h3>

        <div className="container">
          <h3 className="boxed-title">CONTACTO</h3>
          <div className="row pb-5">
            <div
              className="col-md-6"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <div className="row">
                <div className="col-3">
                  <MailOutlineIcon color="primary" className="float-right" />
                </div>
                <div className="col-9">
                  <a className="my-auto" href="mailto:ventas@collage.com.uy">
                    ventas@collage.com.uy
                  </a>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-3">
                  <LocationOnIcon color="primary" className="float-right" />
                </div>
                <div className="col-9">
                  Jaime Cibils 2504 of. 302 / Montevideo
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-3">
                  <PhoneIcon color="primary" className="float-right" />
                </div>
                <div className="col-9">
                  <p className="mb-3">2480 0650</p>
                  <p>2481 2320</p>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-3"></div>
                <div className="col-9">
                  <p>CollageStoreUY</p>
                  <IconButton color="primary">
                    <a
                      href={facebookPage}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FacebookIcon color="primary" />
                    </a>
                  </IconButton>
                  <IconButton color="primary">
                    <a
                      href={instagramPage}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <InstagramIcon color="primary" />
                    </a>
                  </IconButton>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="mx-auto">
                <form
                  id="product-consultation"
                  className="product-consultation contact-consultation"
                  method="POST"
                  action={urlMail}
                >
                  <label className="my-2">Nombre (requerido)</label>
                  <input
                    id="nombre"
                    name="nombre"
                    type="text"
                    required
                    style={{ backgroundColor: 'rgba(238, 238, 238, 1)' }}
                  />

                  <label className="my-2">Teléfono / Celular (requerido)</label>
                  <input
                    id="telefono"
                    name="telefono"
                    type="text"
                    required
                    style={{ backgroundColor: 'rgba(238, 238, 238, 1)' }}
                  />

                  <label className="my-2">Correo electrónico (requerido)</label>
                  <input
                    id="mailfrom"
                    name="mailfrom"
                    type="email"
                    required
                    style={{ backgroundColor: 'rgba(238, 238, 238, 1)' }}
                  />

                  <label className="my-2">Empresa</label>
                  <input
                    id="empresa"
                    name="empresa"
                    type="text"
                    style={{ backgroundColor: 'rgba(238, 238, 238, 1)' }}
                  />

                  <label className="my-2">Consulta (requerido)</label>
                  <textarea
                    name="mensaje"
                    id="mensaje"
                    required
                    style={{ backgroundColor: 'rgba(238, 238, 238, 1)' }}
                  ></textarea>

                  <input type="hidden" name="redirect" value={graciasUrl} />
                  <input
                    type="hidden"
                    name="subject"
                    value="Consulta desde el sitio collagestore.com.uy"
                  />
                  <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className="float-left"
                  >
                    ENVIAR <i className="ml-1 fa fa-caret-right"></i>
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contacto
