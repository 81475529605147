import React, { useEffect } from 'react'
import { Header } from '../organisms'
import imgNosotros from '../../assets/img/nosotros.jpg'
import { useHistory } from 'react-router-dom'

const Nosotros = () => {
  const history = useHistory()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="products">
      <Header />

      <div className="main-content nosotros">
        <h3 className="page-title">
          <button
            className="btn btn-secondary"
            onClick={() => history.goBack()}
          >
            <i className="fa fa-caret-left mr-1"></i> VOLVER
          </button>
        </h3>

        <div className="container">
          <h3 className="boxed-title">NOSOTROS</h3>

          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12 img-container">
              <img src={imgNosotros} alt="" />
              <br />
              <br />
            </div>

            <div className="col-lg-7 col-md-7 col-sm-12">
              <p className="nosotros-description">
                Presentes en el mercado desde 2001, ofrecemos soluciones
                integrales en merchandising.
                <br />
                Manufacturamos nuestros artículos impulsando la industria
                nacional y complementamos nuestra oferta con productos
                importados.
                <br />
                <br />
                Contamos con una amplia selección de productos, que van desde
                los objetos más simples a otros más sofisticados.
                <br />
                Brindamos la opción, a nuestros clientes, de trabajar juntos
                para crear artículos exclusivos, adecuándonos a sus necesidades
                y posibilidades con ingenio y responsabilidad; haciendo un uso
                eficiente del presupuesto asignado.
                <br />
                <br />
                Fomentamos la relación permanente con el cliente, manteniendo la
                exclusividad del producto cuando es su idea original.
                <br />
                <br />
                Atendemos las necesidades tanto de grandes compañías como de
                pequeños artesanos. Todas las propuestas reciben el mismo
                tratamiento: nuestro compromiso con la calidad y los plazos
                prometidos.
                <br />
                <br />
                Somos proveedores estratégicos, para esto promovemos el
                profesionalismo y las buenas prácticas comerciales.
                <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Nosotros
