import Typography from '@material-ui/core/Typography'
import React from 'react'
import { Header } from '../organisms'

const NotFound = () => {
  return (
    <div className="products">
      <Header />
      <div className="main-content product-list-page">
        <h3 className="page-title">Página no encontrada</h3>
        <div className="container">
          <div
            style={{
              padding: '100px 0px',
              display: 'flex',
              justifyContent: 'center',
            }}
            className="row"
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
              className="col-12"
            >
              <Typography>No encontramos la página que buscás</Typography>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
              className="col-12"
            >
              <Typography>
                <a href="/#productos">Hacé click acá</a> y encontrá los mejores
                productos!
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default NotFound
