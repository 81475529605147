import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import { Routes } from './components';
import { theme } from './assets/theme'

import './assets/css/styles.css';
import './assets/css/whatsapp.css'
import Whatsapp from './components/organisms/Whatsapp';

const useStyles = makeStyles(() => ({
  router: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  }
}));

function App() {
  const classes = useStyles();

  return (
    <>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <div className={classes.router}>
            <Routes />
          </div>
        </BrowserRouter>
      </ThemeProvider>
      <Whatsapp />
    </>
  );
}

export default App;
