import Grid from '@material-ui/core/Grid'
import React, { useEffect, useState } from 'react'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { IconButton } from '@material-ui/core'
import Loader from '../../atoms/Loader'

const CarouselImages = ({ productName, images = [] }) => {
  const [currentImage, setCurrentImage] = useState('')
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    if (images.length && images[currentIndex]) {
      setCurrentImage(images[currentIndex])
    }
  }, [images, currentIndex])

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{ height: '380px', display: 'flex', alignItems: 'center' }}
      >
        {!!currentImage && (
          <img
            src={currentImage}
            className="img-fluid"
            alt={`imagen de producto ${productName}`}
          />
        )}
        {!currentImage && <Loader />}
      </Grid>
      {!!images && !!images.length && (
        <Grid container>
          <Grid item xs={1}>
            <IconButton
              disabled={!currentIndex}
              onClick={() => setCurrentIndex(currentIndex - 1)}
            >
              <NavigateBeforeIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10} style={{ maxHeight: '50px' }}>
            <Grid container justify="space-around">
              {images.map((image, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    style={{
                      height: '48px',
                      border: '1px solid #E5E5E5',
                      cursor: 'pointer',
                    }}
                    onClick={() => setCurrentIndex(index)}
                  >
                    <img
                      className="img-fluid"
                      src={image}
                      alt={`Miniatura de ${productName}`}
                      style={{ height: '100%' }}
                    />
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <IconButton
              disabled={images && currentIndex === images.length - 1}
              onClick={() => setCurrentIndex(currentIndex + 1)}
            >
              <NavigateNextIcon />
            </IconButton>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default CarouselImages
